import { WebContext } from "core/auth/types";
import { initialWebContext } from "core/auth/useWebContext";
import { CredentialInformation } from "core/credentials/types";
import { ScreenSizes } from "core/home/types";
import { ByStringId } from "lib/types/common";
import React, { createContext, useContext } from "react";

export enum CurrentNav {
  COMPANY = "company",
  NOTIFICATION = "notification",
  JOB = "job",
  NONE = "none",
}

export enum ForceCreateModalType {
  JOBS = "jobs",
  NEWS = "news",
  GENERIC = "generic",
}
export interface AppValues extends WebContext {
  // General
  currentNav: CurrentNav | null;
  appFocused: boolean;
  screenSize: ScreenSizes;
  // Login
  phone: string | null;
  verificationCode: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  isSignup: boolean;
  token?: string | null;
  exchangeToken: string | null;

  articlesViewed: number;
  userRefId: string | null;
  country: string | null;
  countryAlpha2: string | null;

  jobsViewed: number;
  jobTitle: string;
  jobLocation: string;
  forceCreateAccountModal: ForceCreateModalType | null;
  totalJobs: string | number;
  defaultResults: number;
  currentPage: number;
  totalPages: number;
  limit: number;
  offset: number;
  refreshContext: boolean;
  refreshCredList: number;
  contextLoading: boolean;
  // temp
  onboardingCredentials: string[];
  supportedCredentialTitles: string[];
  formattedTitles: string[];
  credentials: ByStringId<CredentialInformation>;
  organizationRequiredCredentials: ByStringId<string[]>;
  currentUserCredentials: string[];
  enableSendToPhoneModalOnLaunch: boolean;
}

export const initialValues: AppValues = {
  appFocused: false,
  articlesViewed: 0,
  contextLoading: false,

  country: null,
  countryAlpha2: null,

  currentNav: null,
  currentPage: 1,
  defaultResults: 0,
  email: null,
  firstName: null,
  forceCreateAccountModal: null,

  isSignup: false,
  jobLocation: "",
  jobTitle: "",
  jobsViewed: 0,
  lastName: null,
  limit: 20,
  offset: 0,
  phone: null,
  refreshContext: false,
  refreshCredList: 0,
  screenSize: ScreenSizes.XS,
  totalJobs: 0,
  totalPages: 1,
  userRefId: null,
  verificationCode: null,
  ...initialWebContext,
  credentials: {},

  currentUserCredentials: [],

  enableSendToPhoneModalOnLaunch: false,

  exchangeToken: null,
  
  formattedTitles: [],
  // temp:
  onboardingCredentials: [],
  organizationRequiredCredentials: {},
  supportedCredentialTitles: [],
  token: null,
};

type AppValuesAction = {
  type: "appValues/SET";
  value: Partial<AppValues>;
};

function appValuesReducer(
  state: AppValues,
  action: AppValuesAction
): AppValues {
  switch (action.type) {
    case "appValues/SET": {
      return {
        ...state,
        ...action.value,
      };
    }

    default:
      return state;
  }
}

export interface AppProviderValue {
  setAppValues: (value: Partial<AppValues>) => void;
  getAppValues: () => AppValues;
}

const AppContext = createContext<AppProviderValue | null>(null);

export function useAppValues() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppValues() must be used within a AppProvider.");
  }
  return context;
}

type Props = {
  [k in keyof AppValues]?: AppValues[k];
} & { children: React.ReactNode };

export function AppProvider(props: Props) {
  const { children, ...otherProps } = props;
  const [appValuesState, appValuesDispatch] = React.useReducer<
  React.Reducer<AppValues, AppValuesAction>
  >(appValuesReducer, {
    ...initialValues,
    ...otherProps,
  });
  const getAppValues = React.useCallback((): AppValues => {
    return appValuesState;
  }, [appValuesState]);
  const setAppValues = React.useCallback(
    (value: Partial<AppValues>): void => {
      appValuesDispatch({ type: "appValues/SET", value });
    },
    [appValuesDispatch]
  );

  return (
    <AppContext.Provider value={{ getAppValues, setAppValues }}>
      {children}
    </AppContext.Provider>
  );
}
