import { AppValues } from "lib/context/AppContext";
import { ResponseStatus } from "lib/http/types";
import { useEffect, useState } from "react";
import { getWebContext } from "./service";
import { WebContext } from "./types";

export const initialWebContext: WebContext = {
  showSendAppToPhoneButton: false,
};

export default function useWebContext(
  setAppValues: (value: Partial<AppValues>) => void
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [webContext, setWebContext] = useState<WebContext>(initialWebContext);
  const [error, setError] = useState<string | null>(null);

  const callWebContext = async () => {
    setIsLoading(true);
    setAppValues({ showSendAppToPhoneButton: true });
    const result = await getWebContext();
    if (result.status === ResponseStatus.SUCCESS) {
      setWebContext(result.body);
      setAppValues(result.body);
    } else {
      setError(result.error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    callWebContext();
  }, []);

  return { error, isLoading, webContext };
}
