export enum AppRoutes {
  JOBS_HOME = "/jobs",
  NEWS_HOME = "/news",
}

export enum ScreenSizes {
  XS = "xs", // 0
  SM = "sm", // 450
  MD = "md", // 768
  LG = "lg", // 1024
  XL = "xl", // 1500
}
