import { APIResponseUnion } from "lib/http/api";
import { call } from "lib/http/call";
import { ServiceEndpoints } from "lib/http/constants";
import { encodeToCamelCase, encodeToSnakeCase } from "lib/http/formatForServer";
import {
  ContentType,
  MethodType,
  ResponseStatus,
  SuccessMessage,
} from "lib/http/types";
import {
  AuthResponse,
  QuickAuthParams,
  Resume,
  UploadResumeParams,
  WebContext,
} from "./types";

export const quickApply = async (params: QuickAuthParams) => {
  const formData = new FormData();
  const snakeBody = encodeToSnakeCase(params);
  for (const key in snakeBody) {
    if (key !== "resume" && key !== "file_name") {
      formData.append(key, snakeBody[key]);
    }
  }
  if (params.resume) {
    formData.append("resume", params.resume);
    formData.append("file_name", params.fileName ?? "resume_file");
  }

  return await call<AuthResponse>({
    body: formData,
    contentType: ContentType.MP_FORM_DATA,
    endpoint: `${ServiceEndpoints.AUTH}/v1/quick_auth`,
    method: MethodType.POST,
  });
};

export const getPhoneVerificationCode = async (phone: string) => {
  const queryParams = `phone=${phone}`;
  const endpoint = `phone/verification/code?${queryParams}`;
  return await call<SuccessMessage>({
    endpoint: `${ServiceEndpoints.AUTH}/v1/${endpoint}`,
    method: MethodType.GET,
  });
};

export const getWebRedirectKey = async (destination: string) => {
  return await call<AuthResponse>({
    customHeaders: {
      "cache-control": "no-cache",
      pragma: "no-cache",
    },
    endpoint: `${ServiceEndpoints.AUTH}/v1/keys?type=onetime&destination=${destination}`,
    method: MethodType.GET,
  });
};

export const getResumes = async () => {
  return await call<Resume[]>({
    endpoint: `${ServiceEndpoints.AUTH}/v1/resumes`,
    method: MethodType.GET,
  });
};

export const uploadResume = async (params: UploadResumeParams) => {
  const formData = new FormData();
  const snakeBody = encodeToSnakeCase(params);
  for (const key in snakeBody) {
    if (key !== "resume" && key !== "file_name") {
      formData.append(key, snakeBody[key]);
    }
  }
  if (params.resume) {
    formData.append("resume", params.resume);
    formData.append("file_name", params.fileName ?? "resume_file");
  }

  return await call<Resume>({
    body: formData,
    contentType: ContentType.MP_FORM_DATA,
    endpoint: `${ServiceEndpoints.AUTH}/v1/resumes`,
    method: MethodType.POST,
  });
};

export async function getWebContext(): Promise<APIResponseUnion<WebContext>> {
  const result = await fetch(`${ServiceEndpoints.DEEPLINK}/web/context`);
  if (result.ok) {
    const body = await result.json();
    return {
      body: encodeToCamelCase(body) as WebContext,
      status: ResponseStatus.SUCCESS,
    };
  }
  return {
    error: "error",
    status: ResponseStatus.ERROR,
  };
}

type SendAppResponse =
  | { type: ResponseStatus.SUCCESS }
  | { type: ResponseStatus.ERROR; error: string };

export async function handleSendAppToPhone(
  phone: string
): Promise<SendAppResponse> {
  const result = await fetch(
    `${ServiceEndpoints.DEEPLINK}/app/download/link/message?phone=${phone}`
  );
  if (result.ok) {
    return {
      type: ResponseStatus.SUCCESS,
    };
  }
  return {
    error: "error",
    type: ResponseStatus.ERROR,
  };
}
