interface Breakpoints {
  [key: string]: string;
}

interface MaxBreakpoints {
  [key: string]: string;
}

// TODO - switch this to an enum with keyof Breakpoint
const breakpoints: Breakpoints = {
  ">320": "321px",
  ">480": "481px",
  ">768": "769px",
  ">1024": "1025px",
  ">1200": "1201px",
  ">1400": "1401px",
  ">1800": "1801px",
};

// TODO - switch this to an enum with keyof Breakpoint
const maxBreakpoints: MaxBreakpoints = {
  "<320": "320px",
  "<480": "480px",
  "<768": "768px",
  "<1024": "1024px",
  "<1200": "1200px",
  "<1400": "1400px",
  "<1800": "1800px",
};

export const responsiveStyling = (
  incomingBreakpoints: Breakpoints = {}
): string => {
  let mediaQueryOutput = "";

  Object.keys(incomingBreakpoints).map((key) => {
    mediaQueryOutput += `
      @media screen and (min-width: ${breakpoints[key] || key}) {
        ${incomingBreakpoints[key]}
      }
    `;
  });

  return mediaQueryOutput;
};

export const responsiveStylingMax = (
  incomingMaxBreakpoints: MaxBreakpoints = {}
): string => {
  let mediaQueryOutput = "";

  Object.keys(incomingMaxBreakpoints).map((key) => {
    mediaQueryOutput += `
      @media screen and (max-width: ${maxBreakpoints[key] || key}) {
        ${incomingMaxBreakpoints[key]}
      }
    `;
  });

  return mediaQueryOutput;
};

export default responsiveStyling;
